import PaymentView from '@target/views/payment/PaymentView.vue';
import AuthErrorPage from "@target/views/auth-error/AuthErrorPage.vue";
import ChatView from "@/views/chat/ChatView.vue";
import NewPaymentView from "@target/components/popups/create-payment/NewPaymentView.vue";
import NewPaymentPopup from "@target/components/popups/create-payment/NewPaymentPopup.vue";

// Creating new payment
import PopupPaymentCreate from "@/components/popup/popup-payment-create/NewPaymentPopup.vue";
import ViewPaymentCreateConfig from "@/views/payment-create-config/NewPaymentView.vue";
import ViewPaymentCreateAgents from "@/views/payment-create-agents/ViewPaymentCreateAgents.vue";
import PaymentToUpComplete from "@/targets/light/views/payment/PaymentToUpComplete.vue";

const routes: any = [
    {
        path: '/popup/payment/create',
        popup: {
            component: PopupPaymentCreate,
        },
    },
    {
        path: '/payment/create/config',
        component: ViewPaymentCreateConfig,
    },
    {
        path: '/payment/create/agents',
        component: ViewPaymentCreateAgents,
    },
    {
        path: '/popup/main',
        popup: {
            component: NewPaymentPopup,
        },
        options: {
            props: {
                payment: null,
            },
        },
    },
    {
        path: '/popup/new-payment',
        component: NewPaymentView,
    },
    {
        path: '/auth-error',
        component: AuthErrorPage,
    },
    {
        path: '/chat',
        component: ChatView,
    },
    {
        path: '/payment',
        component: PaymentView,
        options: {
            props: {
                fromHistory: false,
            },
        },
    },
    {
        path: '/payment-to-up-complete',
        component: PaymentToUpComplete,
        options: {
            props: {
                fromHistory: false,
            },
        },
    },
];

export default routes;
