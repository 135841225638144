// @ts-ignore
import routes from "@target/core/routes";
import {ref, ToRef} from "@vue/runtime-core";
import LangService from "@/services/LangService";
import {Ref} from "vue";
import SocketService from "@/services/SockerService";
import AbstractBuyingService from "@/services/operations/AbstractBuyingService";
import PaymentService from "@/services/operations/payment/PaymentService";
import RouterService from "@/services/RouterService";
import LightAppController from "@/controllers/app/LightAppController";
import {FirebaseService} from "@/services/firebase/FirebaseService";
import MainApiService from "@/services/api-service/MainApiService";
import LogService from "@/services/log-service/LogService";
import LightPaymentService from "@/targets/light/services/LightPaymentService";
import LightAppService from "@/services/app/LightAppService";
import {FirebaseEvents} from "@/services/firebase/analytics/FirebaseEvents";
import FirebaseEventBuilder from "@/services/firebase/analytics/FirebaseEventBuilder";
import {DocumentTypes} from "@/services/firebase/firestore/documents/DocumentTypes";
import DocumentOthers from "@/services/firebase/firestore/documents/DocumentOthers";
import {AccountError} from "@enums/ServerErrorCodes";
import ServiceAccount from "@/services/v2/data/service-account/ServiceAccount";
import ModelAccount from "@models/v2/account/ModelAccount";
import ServiceMainApi from "@/services/v2/service-main-api/ServiceMainApi";
import {plainToInstance} from "class-transformer";
import LightPaymentPidResponse from "@models/responses/light-payment-pid/LightPaymentPidResponse";
import ServiceTelegram from "@/services/v2/service-telegram/ServiceTelegram";

type PaymentRequestData = { amount: number, psystem_id: number, currency_id?: number, type?: 'purchase' | 'sale' }

export default class AppController extends LightAppController {
    private static instance: AppController | null = null;
    readonly config: object;
    private _isAuth: Ref<Boolean> = ref(false);
    private _isReady: Ref<Boolean> = ref(false);
    private _paymentRequestData: ToRef<PaymentRequestData | null>;
    public socketIsConnecting: Ref<boolean> = ref(false);
    private _lightBuyingService: AbstractBuyingService | null = null;
    private _routerService: RouterService;
    protected _paymentService: LightPaymentService;
    public yandexCaptchaVisible = ref(false);
    public yandexCaptchaID = ref(null);
    public yandexServerCaptchaID = ref(null);

    constructor() {
        super();
        this.config = {
            name: 'light',
            theme: 'ios',
            routes: routes,
            iosTranslucentBars: false,
        }
        this._paymentService = new LightPaymentService();
        this._paymentRequestData = ref(null);
        this._routerService = new RouterService();

        const locale = localStorage.getItem('lang');
        if (locale) {
            LangService.getInstance().set(locale);
        }
    }

    public async initCaptcha(key: string, serverCaptchaId: string, callback: Function) {
        // @ts-ignore
        if (window.smartCaptcha) {
            this.yandexCaptchaVisible.value = true;
            setTimeout(() => {
                // @ts-ignore
                this.yandexCaptchaID.value = window.smartCaptcha.render("captcha-container", {
                    sitekey: key,
                    callback: (token: string) => {
                        callback(token);
                        this.yandexCaptchaVisible.value = false;
                    },
                });
            }, 0);
        } else {
            console.error("SmartCaptcha не загружена");
        }
    }

    get routerService(): RouterService {
        return this._routerService;
    }

    get lightBuyingService(): AbstractBuyingService | null {
        return this._lightBuyingService;
    }

    //TODO not used
    get paymentRequestData() {
        return this._paymentRequestData;
    }

    get isReady() {
        return this._isReady;
    }

    get paymentService(): PaymentService {
        return this._paymentService;
    }

    public async init(callback?: Function) {
        try {
            this._serviceDI = this.createServiceDI();
            this._serviceDI.get<ServiceTelegram>('telegram')?.init();
            let account: ModelAccount | null = null;
            let pusher = this.getPusherSettings();

            const url = new URL(window.location.href);
            if (this.isUrlHasError(url)) {
                this.parseUrlErrorAndSave(url);
                return;
            }

            // @ts-ignore
            if (import.meta.env.VITE_USE_FIREBASE === "true") {
                await FirebaseService.of().init(async () => {
                    const config = await this.fetchFirebaseMainConfigs();
                    if (config) {
                        if (config.pusherKey) pusher.key = config.pusherKey;
                        if (config.mainApiUrl) {
                            pusher.host = config.mainApiUrl.replace('https://', '');
                            pusher.authEndpoint = config.mainApiUrl + "/broadcasting/auth"
                        }
                    }

                    if (FirebaseService.of().firestoreService) {
                        this._paymentService.timerService.updateStatusLiveTimerValues(
                            FirebaseService.of().firestoreService!.parser.makeMapForPurchaseOperationLiveTimers()
                        );
                    }
                });
            }

            if (this.isUrlHasPid(url)) {
                account = await this.initializeWithPid(url);
                this._isAuth.value = true;
            }

            if (account) {
                LangService.getInstance().set(account.settings.localization);
                SocketService.getInstance().init(account.id, pusher).connect();
            }

            LogService.of().log("AppController@init", "initialized");
            this._isReady.value = true;
        } catch (e: any) {
            if (e.data.code === 15005) {
                LogService.of().error("AppController@init", "Captcha error");
                await AppController.of().initCaptcha(
                    e.data.data.client_key,
                    e.data.data.client_id,
                    async (yandexToken: string) => {
                        const response: any = await this.requestCaptchaValidate(yandexToken, e.data.data.captcha_id);
                        if (!response.success) {
                            if (callback) callback();
                            return;
                        }
                        const responseAccount: any = await ServiceMainApi.of().repeatRequest(e.config.url, e.config.config);
                        const aggregateData = plainToInstance(LightPaymentPidResponse, responseAccount.data.data, {
                            excludeExtraneousValues: true,
                            excludePrefixes: ['_']
                        }) as unknown as LightPaymentPidResponse;

                        const {token, refreshToken, csp} = aggregateData.credentials;
                        this.persistCredentials(token, refreshToken, csp);
                        this.token = token;
                        this._paymentService.payment = aggregateData.payment;
                        this._paymentService.paymentError.value = aggregateData.error;

                        ServiceAccount.of().account = aggregateData.profile as ModelAccount;
                        this._isAuth.value = true;

                        if (aggregateData.profile) {
                            LangService.getInstance().set(aggregateData.profile.settings.localization);
                            const pusher = this.getPusherSettings()
                            SocketService.getInstance().init(aggregateData.profile.id, pusher).connect();
                        }

                        LogService.of().log("AppController@init", "initialized");
                        this._isReady.value = true;
                    }
                );
                throw new Error(e);
            }

            this.resetCredentials();
            this.token.value = null;
            LogService.of().error("AppController@init", "didn't initialized");
            this._isReady.value = true;
            throw new Error(e);
        } finally {
        }
    }

    private getPusherSettings() {
        return {
            host: import.meta.env.VITE_API_URL.replace('https://', ''),
            key: import.meta.env.VITE_PUSHER_KEY,
            authEndpoint: import.meta.env.VITE_API_URL + "/broadcasting/auth"
        }
    }

    private async requestCaptchaValidate(token: string, captchaId: string) {
        return await ServiceMainApi.of().post<{ data: object }>(`/api/mobile/captcha/verify`, {
            token,
            captcha_id: captchaId
        });
    }

    private async fetchFirebaseMainConfigs() {
        try {
            const doc = FirebaseService.of().firestoreService!.getDocument(DocumentTypes.OTHERS) as DocumentOthers;
            const mainApiUrl = doc.mainApiUrl;
            const pusherKey = doc.pusherKey;
            if (mainApiUrl) MainApiService.getInstance().setConfigDomain(mainApiUrl);

            return {
                mainApiUrl,
                pusherKey
            }
        } catch (e) {
        }
    }

    protected async initializeWithPid(url: URL) {
        const lightAppService = new LightAppService();

        const pid = this.retrievePidFromUrl(url) as string;
        const aggregateData = await lightAppService.fetchDataWithPid(pid);

        // LogService.of().log("MainResponse", aggregateData);

        const {token, refreshToken, csp} = aggregateData.credentials;
        this.persistCredentials(token, refreshToken, csp);
        this.token = token;
        this._paymentService.payment = aggregateData.payment;
        this._paymentService.paymentError.value = aggregateData.error;

        // this.sendErrorMetrics();
        ServiceAccount.of().account = aggregateData.profile as ModelAccount;
        return aggregateData.profile;
    }

    //TODO not used
    private sendErrorMetrics() {
        LogService.of().log("AppController@sendErrorMetrics", null);
        if (this._paymentService.paymentError.value) {
            if (this._paymentService.paymentError.value.code === AccountError.AgentNotFound) {
                FirebaseService.of().analyticsEvent(
                    FirebaseEvents.PAYMENT_ERROR_NO_AGENTS,
                    FirebaseEventBuilder.of().build()
                );
            }
            if (this._paymentService.paymentError.value.code === AccountError.AgentsUnavailableForAmount) {
                FirebaseService.of().analyticsEvent(
                    FirebaseEvents.PAYMENT_ERROR_WRONG_RANGE,
                    FirebaseEventBuilder.of().build()
                );
            }

            if (this._paymentService.paymentError.value.code === AccountError.AgentsUnavailableForRangeAmount) {
                FirebaseService.of().analyticsEvent(
                    FirebaseEvents.PAYMENT_ERROR_EMPTY_RANGE,
                    FirebaseEventBuilder.of().build()
                );
            }
        }
    }

    //TODO not used
    public setPaymentRequestData(payload: PaymentRequestData | null) {
        this._paymentRequestData.value = payload;
    }

    public async logout() {
        this._isAuth.value = false;
        SocketService.getInstance().disconnect();
        this.resetCredentials();
    }

    //TODO not used
    private checkAuth(): boolean {
        if (localStorage.getItem('ltoken') !== null) {
            return true;
        }
        return false;
    }

    public static getInstance() {
        if (AppController.instance === null) {
            AppController.instance = new AppController()
        }
        return AppController.instance
    }

    public static of() {
        if (AppController.instance === null) {
            AppController.instance = new AppController()
        }
        return AppController.instance
    }
}
